import { ReactNode } from 'react'

interface HomeLayoutProps {
  /**
   * content inside HomeLayout
   */
  children: ReactNode
  forHistoryGeneral?: boolean
}

/**
 * The @HomeLayout is a main layout includes fixed @NavBar and dynamic children
 * @author [anhht]
 * @version 0.1
 */
const HomeLayout = (props: HomeLayoutProps) => {
  return (
    <>
      {/*<div className="fixed top-0 w-full z-50">*/}
      {/*  <NavBar />*/}
      {/*</div>*/}
      <div className="mt-16" />
      {props.children}
    </>
  )
}

export default HomeLayout
